import { signIn, SignInAuthorizationParams } from 'next-auth/react';
import { useCallback } from 'react';

import { useMarketingMetrics } from '../providers/PlausibleAnalyticsProvider';

type SignInProps = {
  provider?: string;
  callbackUrl?: string;
  authParams?: SignInAuthorizationParams;
};
function useSignIn() {
  const { track } = useMarketingMetrics();

  const signin = useCallback(
    async (props?: SignInProps) => {
      track('Login');
      const currentUrl = window.location.href;
      if (props) {
        await signIn(
          props.provider ? props.provider : 'azure-ad-b2c',
          {
            callbackUrl: props.callbackUrl
              ? props.callbackUrl
              : currentUrl
              ? currentUrl
              : '/',
          },
          props.authParams,
        );
      } else {
        await signIn('azure-ad-b2c', {
          callbackUrl: currentUrl ? currentUrl : '/',
        });
      }
    },
    [track],
  );

  return {
    signIn: signin,
  };
}

export default useSignIn;
