import React, { ReactNode } from 'react';

import { NewRelic } from '../hooks/useNewRelic';
class Client500 extends React.Component<{ children: ReactNode }> {
  state: { hasError: boolean } = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: { message: string }) {
    try {
      if (typeof window == 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const newrelic = require('newrelic');
        newrelic?.noticeError(error);
      } else {
        NewRelic()?.noticeError(error);
      }
    } catch (err) {}
  }
  render() {
    if (this.state.hasError) {
      // Fallback error page
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            backgroundColor: '#F9F3EB',
          }}
        >
          <h2 style={{ width: '100%', textAlign: 'center', margin: '20px' }}>
            Oops, there is an error!
          </h2>
          <h3 style={{ width: '100%', textAlign: 'center', margin: '20px' }}>
            We have informed our developers, try again.
          </h3>
          <span style={{ width: '100%', textAlign: 'center' }}>
            If this persists please contact us in the chat or email{' '}
            <span>
              <a href="mailto:frikk@curipod.com" style={{ color: 'blue ' }}>
                frikk@curipod.com
              </a>
            </span>
          </span>

          <a href="/lesson">
            <button
              style={{
                margin: '20px',
                border: 'none',
                backgroundColor: '#33A0DD',
                borderRadius: '10px',
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: '24px',
                padding: '20px',
                color: 'white',
              }}
            >
              Try again
            </button>
          </a>
        </div>
      );
    }

    // All is good
    return this.props.children;
  }
}

export default Client500;
