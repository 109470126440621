import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { NEW_TEMPLATE_PARAM } from '../utils/urlUtils';
import useTenants from './useTenants';

const useRouterMatch = () => {
  const router = useRouter();
  const urlTenantId = router.query.tenantId;
  const [activeTenantId, setActiveTenantId] = useState<string | undefined>(
    urlTenantId as string,
  );
  const { data, filteredTenants } = useTenants();

  useEffect(() => {
    if (data) {
      if (filteredTenants) {
        const hasAccessToTenant = filteredTenants?.user.availableTenants.some(
          tenant => tenant.tenantId === urlTenantId,
        );
        hasAccessToTenant
          ? setActiveTenantId(urlTenantId as string)
          : setActiveTenantId(data.user.activeTenantId);
      } else {
        setActiveTenantId(data.user.activeTenantId);
      }
    }
  }, [data, filteredTenants, router.query, urlTenantId]);

  const gameId = router.query.gameId;
  const templateId = router.query.templateId;
  const generatorId = router.query.generatorId;

  const workspaceId = router.query.workspaceId as string | undefined;
  const parentFolderId = router.query.parentFolderId as string | undefined;
  const creatorId = router.query.creatorId;
  const tab = router.query.tab as string | undefined;
  return {
    router: router,
    tenantId: activeTenantId as string,
    urlTenantId: urlTenantId as string,
    templateId: (templateId === NEW_TEMPLATE_PARAM ? undefined : templateId) as string,
    gameId: gameId as string,
    creatorId: creatorId as string,
    workspaceId: workspaceId,
    parentFolderId: parentFolderId, // backwards compatible for now
    tenantData: data,
    generatorId,
    tab,
  };
};
export default useRouterMatch;
