import { useQuery } from '@tanstack/react-query';

import BackendService from '../services/backendService';
import useRouterMatch from './useRouterMatch';

function useHeartbeat() {
  const { tenantId } = useRouterMatch();

  const { error, isFetching } = useQuery(
    ['hearbeat', tenantId],
    async () => {
      if (!tenantId) {
        return Promise.resolve(undefined);
      }
      const res = await BackendService.instance.sendHeartbeat(tenantId);

      return res;
    },
    {
      enabled: Boolean(tenantId),
      staleTime: 1000 * 60 * 60 * 11,
      refetchInterval: 1000 * 60 * 60 * 12,
    },
  );

  return {
    error,
    isFetching,
  };
}

export default useHeartbeat;
