import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { useMarketingMetrics } from '../PlausibleAnalyticsProvider';

const RouterAnalyticsProvider = ({ children }: { children: React.ReactChild }) => {
  const session = useSession();
  const router = useRouter();
  const { track } = useMarketingMetrics();

  useEffect(() => {
    // Setup tracking for router.events at Nextjs
    const handleRouteChange = () => {
      if (session.status === 'unauthenticated') {
        track('pageview');
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [track, session.status, router.events]);

  useEffect(() => {
    // track initial page loads
    if (session.status === 'unauthenticated') {
      track('pageview');
    }

    // When a user is redirected back to /signup/success
    // Not using router as dependency to prevent duplicate events
    if (
      session.status === 'authenticated' &&
      window.location.pathname === '/signup/success'
    ) {
      track('pageview');
    }
  }, [track, session.status]);

  return <>{children}</>;
};
export default RouterAnalyticsProvider;
