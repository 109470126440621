/**
 * Hack to be able to send custom metrics.
 */

/**
 * Use this when not in the react context
 */
export const NewRelic = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return window?.newrelic;
};

/**
 * Use this in react components to send metrics.
 */
export const useNewRelic = () => {
  return {
    newRelic: NewRelic(),
  };
};

export function newRelicNoticeError(...args: unknown[]) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: { [key: string]: any } = {};
    let argCounter = 1;

    // appends all arguments into a single object
    args.forEach((arg: unknown) => {
      if (typeof arg === 'string') {
        result[`arg${argCounter++}`] = arg;
      } else if (typeof arg === 'object' && !Array.isArray(arg)) {
        Object.assign(result, arg);
      }
    });
    NewRelic()?.noticeError(result);
  } catch (error) {
    NewRelic()?.noticeError({
      message: args,
      newRelicNoticeError: 'Could not parse error object',
    });
  }
}
