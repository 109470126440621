import NextNProgress from 'nextjs-progressbar';
import { ReactNode, useMemo } from 'react';

import theme from '../../assets/theme';

const NextNProgressProvider = ({
  children,
  pathname,
}: {
  children: ReactNode;
  pathname: string;
}) => {
  const showProgressbar = useMemo(() => {
    const disabledPathnames = ['/[tenantId]/reports/[gameId]', '/[tenantId]/home'];

    return !disabledPathnames.includes(pathname);
  }, [pathname]);
  return (
    <>
      {showProgressbar && (
        <NextNProgress
          color={theme.colors.blue}
          startPosition={0.3}
          stopDelayMs={100}
          height={3}
          options={{ easing: 'ease-in-out', speed: 500 }}
          showOnShallow={true}
        />
      )}
      {children}
    </>
  );
};

export default NextNProgressProvider;
