import { config } from '../config/environments.production';
import { isDevelopment, isPR } from './client';

export const getConfig = () => {
  let appConfig = config;
  if (isDevelopment() || isPR()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const devConfig = require('../config/environments').config;
    appConfig = devConfig;
  }
  return appConfig;
};

export const configureStore = () => {
  const config = getConfig();

  return { config };
};
