import { LocalStorageProvider } from 'unleash-proxy-client';

import { isBrowser } from './navigator';

export const unleashFetchOverride = (url: string, opts: RequestInit) => fetch(url, opts);

export const unleashStorageProviderOverride = () =>
  isBrowser()
    ? new LocalStorageProvider()
    : {
        get: (name: string) => Promise.resolve(undefined),
        save: (name: string) => Promise.resolve(undefined),
      };
