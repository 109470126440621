import Router, { useRouter } from 'next/router';
// import { useHistory, useLocation } from 'react-router-dom';

export const useQuery = () => {
  // const { search, pathname } = useLocation();

  const { query, pathname, replace, asPath } = useRouter();
  // const history = useHistory();
  const path = asPath.split('?');
  const search = path.length > 1 ? path[1] : '';

  return {
    query: new URLSearchParams(search),
    search: asPath,

    nextQuery: query,
    clear: () => replace(pathname),
    // setOnLoginRedirect: (from: string) =>
    //   replace(`${pathname}?${search}&from=${from}`),
  };
};
