export function shuffleArray<T>(initArray: T[]): T[] {
  const array = JSON.parse(JSON.stringify(initArray));
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const parseArray = (value: string | null | undefined, isMetrics?: boolean) => {
  try {
    return JSON.parse(value || '[]');
  } catch (error) {
    if (isMetrics) [value];
    return [];
  }
};
