// eslint-disable-next-line @typescript-eslint/no-var-requires
const LanguageDetector = require('i18next-browser-languagedetector').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LocizeBackend = require('i18next-locize-backend/cjs');
const isBrowser = typeof window !== 'undefined';
module.exports = {
  defaultNS: 'Host',
  // debug: true,
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'nb-NO'],
    localeDetection: false,
  },
  use: isBrowser ? [LanguageDetector] : [],
  backend: {
    projectId: '82081248-4597-485a-8a16-ca9695f6957d',
    apiKey: 'edd162a8-ac81-4901-b399-6d8b6ab5073c',
    version: 'latest',
  },
  serializeConfig: false,
  use: isBrowser ? [LocizeBackend] : [],
  saveMissing: process.env.NODE_ENV === 'development',
};
