import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import BackendService from '../services/backendService';
import { UserTenant } from '../services/UserTenantsService';
import { isLaunchedInPWA } from '../utils/pwaUtils';

function useTenants(openNewTab?: boolean) {
  const tenantsCache = 'tenants';
  const router = useRouter();

  const tenantIdByUrl: string | undefined = router.query.tenantId as string | undefined;
  const client = useQueryClient();
  const [hasAccessToTenantByUrl, setHasAccessToTenantByUrl] = useState(true);
  const { data, isFetching, error } = useQuery(
    [tenantsCache, tenantIdByUrl],
    () => {
      return BackendService.instance.retrieveCurrentTenants();
    },
    {
      onSettled: res => {
        if (
          res &&
          res.user.availableTenants.find(
            t => t.tenantId === tenantIdByUrl || t.tenantId === data?.user.activeTenantId,
          )
        ) {
          setHasAccessToTenantByUrl(true);
        } else {
          setHasAccessToTenantByUrl(false);
        }
      },
      staleTime: 5000 * 60 * 5,
    },
  );

  const { mutate } = useMutation<UserTenant, void, string>(
    (tenantId: string) =>
      BackendService.instance.switchTenant(
        tenantId,
        tenantIdByUrl ? tenantIdByUrl : data?.user.activeTenantId,
      ),
    {
      onSuccess: res => {
        client.invalidateQueries([tenantsCache]);
        if (isLaunchedInPWA()) {
          window.location.replace(`${window.location.origin}/${res.activeTenantId}`);
          return;
        }
        if (openNewTab) {
          window.open(`${window.location.origin}/${res.activeTenantId}`, '__blank');
        } else {
          window.open(`${window.location.origin}/${res.activeTenantId}`, '__blank');
        }
      },
    },
  );

  const filteredTenants = useMemo(() => {
    if (data) {
      return {
        ...data,
        user: {
          ...data.user,
          availableTenants: data.user.availableTenants,
        },
      };
    }
  }, [data]);

  return {
    data,
    filteredTenants,
    error,
    isLoading: isFetching,
    switch: mutate,
    hasAccessToTenantByUrl,
  };
}

export default useTenants;
