import { useInfiniteQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { useMarketingMetrics } from '../providers/PlausibleAnalyticsProvider';
import backendService from '../services/backendService';
import { DiscoverSearchQuery } from '../services/backendService/types';
import { toTemplateSearchQueryCacheKey } from '../utils/react-query-utils';
import { toSearchParams } from './useDiscoverSearchQueryState';
import { useMetrics } from './useMetrics';
export const BASE_TEMPLATE_SEARCH_LIMIT = 20;

function useTemplateSearch(
  query?: DiscoverSearchQuery,
  cacheKey?: string[],
  eventPostFix?: string,
) {
  const metrics = useMetrics();
  const session = useSession();
  const { track } = useMarketingMetrics();
  const realQuery = {
    ...query,
    limit: BASE_TEMPLATE_SEARCH_LIMIT,
    cursor: 0,
  };
  const performSearch = ({ pageParam = 0 }: { pageParam?: number }) =>
    backendService.instance.discover.anonymised.query({
      ...realQuery,
      cursor: pageParam,
    });

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    cacheKey ? cacheKey : ['template-search', toTemplateSearchQueryCacheKey(realQuery)],
    performSearch,
    {
      onSuccess: data => {
        if (session.status === 'authenticated') {
          metrics.logEvent(
            `Template.Search.ResultsCount${eventPostFix ? '.' + eventPostFix : ''}`,
            {
              query: realQuery ? toSearchParams(realQuery).toString() : undefined,
              totalResults: data.pages.length > 0 ? data.pages[0].total : 0,
            },
            //TODO: remove these ones?
          );
        } else {
          track(`Template.Search.ResultsCount${eventPostFix ? '.' + eventPostFix : ''}`, {
            props: {
              query: realQuery ? toSearchParams(realQuery).toString() : undefined,
              totalResults: data.pages.length > 0 ? data.pages[0].total : 0,
            },
          });
        }
      },
      getNextPageParam: lastPage => lastPage.cursor,
      staleTime: 1000 * 25,
    },
  );

  return {
    items: data,
    error,
    isLoading: isFetching,
    isFetchingMore: isFetchingNextPage,
    canFetchMore: hasNextPage,
    fetchMore: fetchNextPage,
  };
}

export default useTemplateSearch;
