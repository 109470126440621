import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

import { useMarketingMetrics } from '../providers/PlausibleAnalyticsProvider';
import { DiscoverSearchQuery } from '../services/backendService/types';
import { useMetrics } from './useMetrics';
import { useQuery } from './useQuery';
import { BASE_TEMPLATE_SEARCH_LIMIT } from './useTemplateSearch';

const toSearchQuery = (
  query: URLSearchParams,
  lang?: string,
): DiscoverSearchQuery | undefined => {
  const cursor = query.get('cursor');
  const searchPhrase = query.get('search');
  const subjects = query.get('subjects');
  const hotFilter = query.get('hotFilter');
  const languages = lang ? lang : query.get('languages');
  const grades = query.get('grades');
  const filters: DiscoverSearchQuery['filters'] =
    subjects || languages || grades || hotFilter
      ? {
        grades: grades ? grades.split(',').sort() : undefined,
        languages: languages ? languages.split(',').sort() : undefined,
        subjects: subjects ? subjects.split(',').sort() : undefined,
        hotFilter: hotFilter ? hotFilter.split(',').sort() : undefined,
      }
      : undefined;
  return {
    cursor: cursor ? Number(cursor) : undefined,
    searchPhrase: searchPhrase ? searchPhrase : undefined,
    filters,
    limit: BASE_TEMPLATE_SEARCH_LIMIT,
  };
};

export const toSearchParams = (query: DiscoverSearchQuery) => {
  const searchParams = new URLSearchParams();
  if (query.searchPhrase) {
    searchParams.set('search', query.searchPhrase);
  }
  if (query.limit) {
    searchParams.set('limit', query.limit.toString());
  }
  if (query.cursor) {
    searchParams.set('cursor', query.cursor.toString());
  }
  if (query.filters?.grades) {
    searchParams.set('grades', query.filters?.grades.sort().join(','));
  }
  if (query.filters?.subjects) {
    searchParams.set('subjects', query.filters?.subjects.sort().join(','));
  }
  if (query.filters?.languages) {
    searchParams.set('languages', query.filters?.languages.sort().join(','));
  }
  if (query.filters?.hotFilter) {
    searchParams.set('hotFilter', query.filters?.hotFilter.sort().join(','));
  }

  return searchParams;
};

export const toGeneratorSearchParams = ({
  cursor,
  grades,
  categories,
  limit,
  subjects,
  statuses,
  searchPhrase,
  creatorId,
}: {
  cursor: number;
  subjects: string[];
  categories?: string[];
  creatorId?: string;
  searchPhrase?: string;
  grades: string[];
  statuses?: string[];
  limit: number;
}) => {
  const searchParams = new URLSearchParams();
  if (limit) {
    searchParams.set('limit', limit.toString());
  }
  if (cursor) {
    searchParams.set('cursor', cursor.toString());
  }
  if (grades && grades.length > 0) {
    searchParams.set('grades', grades.join(','));
  }
  if (subjects && subjects.length > 0) {
    searchParams.set('subjects', subjects.sort().join(','));
  }

  if (categories && categories.length > 0) {
    searchParams.set('categories', categories.sort().join(','));
  }

  if (creatorId) {
    searchParams.set('creatorId', creatorId);
  }

  if (statuses && statuses.length > 0) {
    searchParams.set('statuses', statuses.sort().join(','));
  }
  if (searchPhrase && searchPhrase.length > 0) {
    searchParams.set('searchPhrase', searchPhrase);
  }

  return searchParams;
};

export const useDiscoverSearchQueryState = (lang?: string) => {
  const { query: rawQuery } = useQuery();
  const router = useRouter();
  const state = useMemo(() => toSearchQuery(rawQuery, lang), [rawQuery, lang]);
  const metrics = useMetrics();
  const session = useSession();
  const { track } = useMarketingMetrics();

  const addFilter = ({ type, value }: { type: string; value: string }) => {
    metrics.logEvent('DiscoverFilters.AddFilter', { type, value });
    const existing = rawQuery.get(type);
    const subjectFilterExisting = rawQuery.get('subjects');

    if (subjectFilterExisting) {
      rawQuery.delete('subjects');
    }

    if (type === 'hotFilter') {
      rawQuery.set(type, value);
    } else if (!existing) {
      rawQuery.set(type, value);
    } else {
      const array = existing.split(',');
      rawQuery.set(
        type,
        array
          .concat([value])
          .sort()
          .join(','),
      );
    }
    router.push({
      query: rawQuery.toString(),
    });
  };

  const selectAllCategory = () => {
    const subjectFilterExisting = rawQuery.get('subjects');

    if (subjectFilterExisting) {
      rawQuery.delete('subjects');
      router.push({
        query: rawQuery.toString(),
      });
    }
  };

  const removeFilter = ({ type, value }: { type: string; value?: string }) => {
    metrics.logEvent('DiscoverFilters.RemoveFilter', { type, value });
    const existing = rawQuery.get(type);
    if (!existing) {
      return;
    }
    const array = existing.split(',').filter(v => v !== value);
    if (array.length === 0) {
      rawQuery.delete(type);
    } else {
      rawQuery.set(type, array.sort().join(','));
    }
    router.push({
      query: rawQuery.toString(),
    });
  };

  const setSearchPhrase = (value: string) => {
    if (value.length === 0) {
      rawQuery.delete('search');
    } else {
      if (session.status === 'authenticated') {
        metrics.logEvent('DiscoverFilters.Search', { value });
      } else {
        track('DiscoverFilters.Search', { props: { search: value } });
      }
      rawQuery.set('search', value);
    }
    router.push({
      query: rawQuery.toString(),
    });
  };

  const clearAllFilters = () => {
    metrics.logEvent('DiscoverFilters.Clear');
    router.push({
      query: '',
    });
  };

  return {
    rawQuery,
    state,
    addFilter,
    removeFilter,
    setSearchPhrase,
    clearAllFilters,
    selectAllCategory,
  };
};
