const configEnv = process.env.CONFIG_ENV || 'development';

// TODO: WILL THESE WORK IN PROD?
// TODO: WILL THESE WORK IN PROD?

const firebaseConfig =
  configEnv === 'development'
    ? {
        databaseURL: 'https://snapmentor-1.firebaseio.com',
        projectId: 'snapmentor-1',
        storageBucket: 'snapmentor-1.appspot.com',
      }
    : {
        databaseURL: 'https://snapmentor-prod.firebaseio.com',
        projectId: 'snapmentor-prod',
        storageBucket: 'snapmentor-prod.appspot.com',
      };

export const preventRedirectOn401Pahtnames = [
  '/about-us',
  '/contact-us',
  '/pricing',
  '/careers',
  '/community',
  '/ai-certification',
  '/ambassadors',
  '/privacy-policy',
  '/state-and-district-privacy-agreements',
  '/cookie-policy',
  '/ai-in-curipod',
  '/terms-of-use',
  '/gdpr',
  '/ai',
  '/daily-icebreaker',
  '/accelerator',
  '/creators',
  '/business',
  '/accessibility',
];
export default {
  ci: process.env.CI,
  logger: {
    type: 'cloud_run_revision',
    loggerName: 'curipod-com',
  },
  discover: {
    cdn: {
      bucket: process.env.DISCOVER_CDN_BUCKET || 'images-curipod-staging',
    },
  },
  cms: {
    notionToken: process.env.NOTION_TOKEN,
    notionDB: '5971c8e1ca09475c92ea4f34341b0e5b',
  },
  testEnvironment: process.env.TEST === 'true',
  environment: process.env.NODE_ENV,
  isStandalone: true,
  firebase: { ...firebaseConfig },
  demoGame: {
    templateId: process.env.DEMO_TEMPLATE_ID || 'EFj58fB3XpWfLUuLz9wl',
    tenantId: process.env.DEMO_GAME_TENANT_ID || 'cf3dcdb5-bfef-4583-9296-ecc53665088c',
  },
  azure: {
    clientId: process.env.AZURE_CLIENT_ID || '13f11d88-66e2-49da-965f-1459dd4d42d8',
    clientSecret: process.env.AZURE_CLIENT_SECRET || '_qzH-Na3ne1I-Ww90.74_jtinRjlSf-1q.',
    tenantId: process.env.AZURE_TENANT_ID || '435f1c4d-058f-46a8-8f66-eb6d239459fd',
  },
  azureb2c: {
    clientId: process.env.AZURE_B2C_CLIENT_ID || 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
    tenantName: process.env.AZURE_B2C_TENANT_NAME || 'snapmentordev',
    tenantId: process.env.AZURE_B2C_TENANT_ID || '435f1c4d-058f-46a8-8f66-eb6d239459fd',
    policyName: process.env.AZURE_B2C_POLICY_NAME || 'B2C_1A_signup_signin',
    tokenAudience:
      process.env.AZURE_B2C_TOKEN_AUDIENCE || 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
  },
  elasticDiscover: {
    cloud_id:
      process.env.ELASTIC_CLOUD_ID ||
      'Snapmentor_dev:ZXVyb3BlLXdlc3QzLmdjcC5jbG91ZC5lcy5pbyQxMTU5OTUwYzJiZGE0ZDY3OWY5ZmZiMTcwN2Y4ZGVlMiQ0MGVmMGM4NmIyOTM0NmYwOWMzYWYzMTViZjYyM2U3OA==',
    username: process.env.ELASTIC_USERNAME || 'elastic',
    password: process.env.ELASTIC_PASSWORD || 'dBaIonuHyW99dpa82EnSajmz',
  },
  authTokenSecret: process.env.AUTH_TOKEN_SECRET,
  slackBotToken:
    process.env.SLACK_BOT_TOKEN ||
    'xoxb-676592368754-1649555902307-kmoSXy1xIkHos3TgRjCYc7g3',
  region: process.env.K_SERVICE,
  game: {
    authSecret: process.env.GAME_AUTH_SECRET || 'frikkerkul',
  },
  files: {
    bucket:
      configEnv === 'production'
        ? 'snapmentor-prod.appspot.com'
        : 'snapmentor-1.appspot.com',
  },
  slack: {
    discover: {
      channel:
        configEnv === 'production'
          ? '#discover-activity-feed'
          : '#discover-activity-feed-dev',
    },
  },

  discoverAdmin: {
    tenantIds: (process.env.DISCOVER_ADMIN_TENANTS || 'metered-dev').split(','),
  },
  redis: {
    host: process.env.REDIS_HOST || '127.0.0.1',
  },
};
