// Make sure we import our global css after antd so make sure styles are properly overwritten
import 'antd/dist/antd.less';
import '../styles/globals.css';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { DehydrateOptions, Hydrate, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  PersistQueryClientOptions,
  PersistQueryClientProvider,
} from '@tanstack/react-query-persist-client';
import FlagProvider, { UnleashClient } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider, useSession } from 'next-auth/react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import smoothscroll from 'smoothscroll-polyfill';

import Client500 from '../components/Client500';
import { AppPropsWithLayout } from '../components/layouts';
import useHeartbeat from '../hooks/useHeartbeat';
import useIdentifyMetrics from '../hooks/useIdentifyMetrics';
import useSignIn from '../hooks/useSignIn';
import nextI18nextConfig from '../next-i18next.config';
import { CustomIntercomStateProvider } from '../providers/CustomIntercomStateProvider';
import NextNProgressProvider from '../providers/NextNProgressProvider';
import OfflineNotificationProvider from '../providers/OfflineNotificationProvider';
import { PlausibleAnalyticsProvider } from '../providers/PlausibleAnalyticsProvider';
import RouterAnalyticsProvider from '../providers/RouterAnalyticsProvider';
import RouterHistoryProvider from '../providers/RouterHistoryProvider';
import BackendService from '../services/backendService';
import Metrics from '../services/metrics';
import { getBaseUrl, isDevelopment } from '../utils/client';
import { configureStore } from '../utils/store';
import { unleashFetchOverride, unleashStorageProviderOverride } from '../utils/unleash';

const MetricsIdentifier = ({ uid }: { uid: string }) => {
  useIdentifyMetrics(uid);
  useHeartbeat();
  return null;
};

const BackendServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSession();
  const { signIn } = useSignIn();
  const { config } = useMemo(() => configureStore(), []);
  useState(() => {
    new BackendService().init(config.backend.externalApiUrl, config.cdn.url);
    if (session && session.data?.idToken) {
      BackendService.instance.setToken(session.data.idToken as string);
    }
  });

  useEffect(() => {
    if (session && session.data?.idToken) {
      BackendService.instance.setToken(session.data.idToken as string);
    }
  }, [config.backend.externalApiUrl, config.cdn.url, session]);

  useEffect(() => {
    if (session?.data?.error === 'RefreshAccessTokenError') {
      signIn(); // Force sign in to hopefully resolve error
    }
  }, [session, signIn]);

  return (
    <>
      {session?.data?.user?.uid && <MetricsIdentifier uid={session.data.user.uid} />}

      {children}
    </>
  );
};

function App({
  Component,
  pageProps: { session, unleash, ...pageProps },
}: AppPropsWithLayout) {
  const { config } = configureStore();
  const router = useRouter();
  useState(() => {
    new Metrics();
    Metrics.init(config.mixpanel.token);
  });

  const { i18n } = useTranslation('Host');
  const [persister] = useState<Omit<PersistQueryClientOptions, 'queryClient'>>(() => {
    const dehydrateOptions: DehydrateOptions = {
      shouldDehydrateQuery: query => {
        return query.queryKey.includes('persist');
      },
    };
    return {
      persister: createSyncStoragePersister({
        storage: typeof window !== 'undefined' ? window.localStorage : undefined,
      }),
      maxAge: 1000 * 60 * 60 * 24, // 24 hours
      dehydrateOptions,
    };
  });

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
      },
    });
  });

  const [unleashClient] = useState(
    () =>
      new UnleashClient({
        ...config.unleash,
        bootstrap: unleash?.toggles, // Pass toggles fetched SSR
        context: unleash?.context, // Pass context fetched SSR to avoid flicker
        fetch: unleashFetchOverride,
        storageProvider: unleashStorageProviderOverride(),
      }),
  );

  const [] = useState(() => {
    dayjs.extend(updateLocale);
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
  });

  useEffect(() => {
    if (i18n.language) {
      if (i18n.language === 'en') {
        // Attempt to change the html lang property client side
        const m = document.querySelector('html');
        m?.setAttribute('lang', 'en');

        dayjs.locale('en');
        dayjs.updateLocale('en', {
          relativeTime: {
            future: 'in %s',
            past: '%s',
            s: function() {
              return `just now`;
            },
            m: function() {
              return `1m`;
            },
            mm: function(number: string) {
              return `${number}m`;
            },
            h: function() {
              return `1h`;
            },
            hh: function(number: string) {
              return `${number}h`;
            },
            d: function() {
              return `1d`;
            },
            dd: function(number: string) {
              return `${number}d`;
            },
            M: function() {
              return `1mo`;
            },
            MM: function(number: string) {
              return `${number}mo`;
            },
            y: function() {
              return `1y`;
            },
            yy: function(number: string) {
              return `${number}y`;
            },
          },
        });
      }
      if (i18n.language === 'nb-NO') {
        import(`dayjs/locale/nb`).then(() => {
          // Attempt to change the html lang property client side
          const m = document.querySelector('html');
          m?.setAttribute('lang', 'nb-NO');

          dayjs.locale('nb');
          dayjs.updateLocale('nb', {
            relativeTime: {
              future: 'om %s',
              past: '%s',
              s: function() {
                return `nå`;
              },
              m: function() {
                return `1m`;
              },
              mm: function(number: string) {
                return `${number}m`;
              },
              h: function() {
                return `1t`;
              },
              hh: function(number: string) {
                return `${number}t`;
              },
              d: function() {
                return `1d`;
              },
              dd: function(number: string) {
                return `${number}d`;
              },
              M: function() {
                return `1må`;
              },
              MM: function(number: string) {
                return `${number}må`;
              },
              y: function() {
                return `1å`;
              },
              yy: function(number: string) {
                return `${number}å`;
              },
            },
          });
        });
      }
    }
  }, [i18n]);

  const [] = useState(() => {
    const bService = new BackendService();
    bService.init(config.backend.externalApiUrl, config.cdn.url);

    return bService;
  });

  const getLayout = Component.getLayout ?? (page => page);
  useEffect(() => {
    // kick off the polyfill for smooth scrolling on safari and other browsers
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Head>
        <title>Curipod - Spark curiosity with interactive lessons</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 viewport-fit=cover"
        />
        <meta
          name="description"
          content={`Free ready to play interactive lessons that sparks your students curiosity in the classroom`}
          key="namedescription"
        />
        <link rel="shortcut icon" href="/static/logo.ico" />
        <meta
          property="og:title"
          content={`Curipod - Spark curiosity with interactive lessons`}
          key="title"
        />
        <meta
          name="twitter:title"
          content={`Curipod - Spark curiosity with interactive lessons`}
        />
        <meta
          property="description"
          content={
            'Free ready to play interactive lessons that sparks your students curiosity in the classroom'
          }
          key="description"
        />
        <meta
          property="og:description"
          content={`Free ready to play interactive lessons that sparks your students curiosity in the classroom`}
          key="og:description"
        />
        <meta
          name="twitter:description"
          content={`Free ready to play interactive lessons that sparks your students curiosity in the classroom`}
        />
        <meta property="og:image" content={'/og_preview.png'} key="imageurl" />
        <meta
          property="og:image:secure_url"
          content={'/og_preview.png'}
          key="imagesecureurl"
        />
        <meta property="og:type" content="website" />

        <meta property="og:url" content={`${getBaseUrl()}`} key="ogurl" />
        <meta property="og:site_name" content="Curipod" />
        <meta property="og:image:type" content={'image/png'} key="imagetype" />
        <meta property="og:image:alt" content={'Curipod logo' || ''} key="imageAlt" />
        <meta property="og:image:width" content={'300'} key="imageWidth" />
        <meta property="og:image:height" content={'190'} key="imageHeight" />
        <meta name="twitter:image" content={'/og_preview.png'} />
        <meta name="twitter:card" content={`summary`} />

        <link rel="canonical" href={`${getBaseUrl()}${router.asPath}`} />
      </Head>

      <PlausibleAnalyticsProvider
        trackLocalhost={false}
        domain={
          isDevelopment()
            ? 'test-landing.curipod.com'
            : getBaseUrl()
                .replace('http://', '')
                .replace('https://', '')
        }
      >
        <NextNProgressProvider pathname={router.pathname}>
          <CustomIntercomStateProvider>
            <Client500>
              <SessionProvider session={session}>
                <RouterAnalyticsProvider>
                  <PersistQueryClientProvider
                    client={queryClient}
                    persistOptions={persister}
                  >
                    <Hydrate state={pageProps.dehydratedState}>
                      <OfflineNotificationProvider>
                        <RouterHistoryProvider>
                          <ParallaxProvider>
                            <FlagProvider unleashClient={unleashClient}>
                              <BackendServiceProvider>
                                {getLayout(
                                  <>
                                    <Component {...pageProps} />
                                  </>,
                                )}
                              </BackendServiceProvider>
                            </FlagProvider>
                          </ParallaxProvider>
                        </RouterHistoryProvider>
                      </OfflineNotificationProvider>
                    </Hydrate>
                    <ReactQueryDevtools />
                  </PersistQueryClientProvider>
                </RouterAnalyticsProvider>
              </SessionProvider>
            </Client500>
          </CustomIntercomStateProvider>
        </NextNProgressProvider>
      </PlausibleAnalyticsProvider>
    </>
  );
}

export default appWithTranslation(App, nextI18nextConfig);
