export const config = {
  cloudMessaging: {
    vapidKey:
      'BFXyM2M44MjtaQSH4B3s1jycvLnm9v3DcWgqwC1JoqDnvX0I0dW-8BvDZ4s4OuR1v31pduColECCdI99_jv-E1A',
  },
  backend: {
    externalApiUrl: 'https://api-test.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn-staging.curipod.com',
  },
  sentry: {
    dsn: 'https://f7e5e7f1902940f09a4732ad668667d2@sentry.io/1757023',
  },
  stripe: {
    publicKey: 'pk_test_hFHXs9lIjMZOiaqXp6D2SwSX00AfqbYr2P',
  },
  appVersion: process.env.REACT_APP_VERSION,
  mixpanel: {
    token: 'b3147091d8b6e435d0dfeb43c9e3669c',
  },
  azure: {
    tenant: 'snapmentordev.onmicrosoft.com',
    tenantName: 'snapmentordev',
    clientId: 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentordev.onmicrosoft.com/cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6/User.Read',
    ],
    enterpriseRedirectUri: 'https://id-dev.curipod.com',
    prRedirectUri: 'https://id-dev.curipod.com',
    redirectUri: ``,
  },
  onboarding: {
    version: 24,
  },
  whatsNew: {
    version: 24,
  },
  game: {
    url: 'dev.curi.live',
    apiUrl: 'https://dev-api-live.curipod.com',
  },
  legacyApp: {
    url: 'https://app-dev.curipod.com',
  },
  externalLinks: {
    howItWorks: 'https://test-landing.curipod.com/how-it-works',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod-dev',
    refreshInterval: 60 * 5,
  },
  discovery: {
    generatorIds: [
      '53b38a50-c4ed-4bb9-9668-348b4f8620b6', // Phenomena - identify the lie - generator
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
      'ce27a986-9414-46d2-9ae3-102de53b6583', // history lesson
    ],
    fullLessonIds: [
      '09450f64-2788-4287-8af6-4346cee242b2', // full lesson ela example
      // '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      //'15c09734-a940-41e8-87ef-c233b3526dc7', // local lesson
    ],
    fullLessonIdsSocialStudies: [
      // '710c3709-6b3d-4420-9018-47b74a9090f1', // video game
      '5ea0accc-5cd0-4499-b161-e8664f7c3c26', // full lesson social stdies example
    ],
    fullLessonIdsMath: [
      // 'f665ec3d-047d-45fc-bb94-aaf7f5ab06ae', // Lawyers madness
      '506e563a-56aa-4c45-8d55-156d3797497f', // full lesson math example
    ],
    fullLessonIdsScience: [
      // '889c7d87-6f32-4c13-b947-089190b4964f', // animals in the wild
      '1afad20d-bdd7-43f0-953a-77e658f057ef', // full lesson science example
    ],
    didNotFindTryTheseSuggestionIds: [
      '53b38a50-c4ed-4bb9-9668-348b4f8620b6', // Phenomena - identify the lie - generator
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
    ],
    recommended: [
      '12cce8f2-7418-42bc-bd59-944f00dd3a12', // Best lesson ever
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
      '2ebb23e3-0fc2-4635-b2ff-84f07d6ba3ea', // idioms
    ],
  },
};

export type APPConfig = typeof config;
