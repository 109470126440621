import Script from 'next/script';
import { ReactNode, useCallback } from 'react';

import { getBaseUrl } from '../utils/client';

/**
 * First I tried to use next-plausible, but it did support manual. Some bug.
 * So I had a brief look at the code and took the bare minimum of what we need.
 */
export const PlausibleAnalyticsProvider = ({
  domain,
  children,
  trackLocalhost,
}: {
  domain: string;
  children: ReactNode | ReactNode[];
  trackLocalhost?: boolean;
}) => {
  const subDirectoryProxyRewrite = 'stats';
  const scriptFileName = trackLocalhost ? 'script.local.manual.js' : 'script.manual.js';
  return (
    <>
      {/* This adds the script tag that download the analytics script from plausible - via our proxy */}
      <Script
        async
        defer
        data-api={`${getBaseUrl()}/api/${subDirectoryProxyRewrite}/event`}
        data-domain={domain}
        src={`${getBaseUrl()}/api/${subDirectoryProxyRewrite}/js/${scriptFileName}`}
      />
      {/*https://plausible.io/docs/script-extensions*/}
      {/* This makes sure we add the manual pageview function to the
          window element to be used by the usePlausible hook
       */}
      <Script
        id="next-plausible-init"
        dangerouslySetInnerHTML={{
          __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`,
        }}
      />
      {children}
    </>
  );
};

/**
 * Copied from next-plausible
 * https://www.npmjs.com/package/next-plausible
 */
// https://docs.plausible.io/custom-event-goals#using-custom-props
type Props = Record<string, unknown> | never;
type EventOptions<P extends Props> = {
  props: P;
  // https://plausible.io/docs/custom-locations
  u?: string;
  callback?: VoidFunction;
};
type EventOptionsTuple<P extends Props> = P extends never
  ? [Omit<EventOptions<P>, 'props'>?]
  : [EventOptions<P>];
type Events = { [K: string]: Props };

/**
 * Used to track manual pageViews
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMarketingMetrics<E extends Events = any>() {
  const track = useCallback(function<N extends keyof E>(
    eventName: N,
    ...rest: EventOptionsTuple<E[N]>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).plausible?.(eventName, rest[0]);
  },
  []);
  return { track };
}
