import { GetServerSidePropsContext, PreviewData } from 'next';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { ParsedUrlQuery } from 'querystring';
export type NextContext = GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
export const isSafari =
  typeof window !== 'undefined' &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isMac =
  typeof window !== 'undefined' && navigator.platform.toUpperCase().indexOf('MAC') >= 0;

export const ctrlOrCMD = isMac ? '⌘' : 'ctrl';
export const backspace = isMac ? '⌫' : 'Delete';
export const shift = isMac ? '⇧' : '⇧';

export const isIos =
  typeof window !== 'undefined' &&
  (!!navigator.userAgent.match(/iPad|iPhone|iPod/i) ||
    (isMac && navigator.maxTouchPoints > 0));
export const getDestinationTenantFromUrl = (activeTenantId?: string) => {
  if (typeof window !== 'undefined') {
    const destinationTenantId = window.location.pathname.split('/')[1];
    if (!destinationTenantId && !activeTenantId) {
      throw new Error(
        'getDestinationTenantFromUrl Should not be called until we have a destination tenant',
      );
    }
    if (destinationTenantId === 'join') {
      throw new Error('Should not use join as a tenant destination');
    }
    if (destinationTenantId === 'fromtemplate') {
      throw new Error('Should not use join as a tenant destination');
    }
    if (destinationTenantId === 'share') {
      throw new Error('Should not use share as a tenant destination');
    }
    if (destinationTenantId === 'discover' && activeTenantId) {
      return activeTenantId;
    }
    if (destinationTenantId === 'feedback' && activeTenantId) {
      return activeTenantId;
    }
    if (destinationTenantId === 'ai' && activeTenantId) {
      return activeTenantId;
    }
    if (destinationTenantId === 'generators' && activeTenantId) {
      return activeTenantId;
    }
    if (activeTenantId) {
      return activeTenantId;
    }
    return destinationTenantId;
  }
};

export const getOrigin = () => {
  try {
    return window.location.origin;
  } catch (e) {
    // this is now in the service worker
    // eslint-disable-next-line no-console

    // eslint-disable-next-line no-restricted-globals
    return self.origin;
  }
};

export const isBrowser = () => {
  if (typeof window !== 'undefined') {
    // Client-side-only code
    return true;
  }
  return false;
};

export const withAuthRedirect = async ({
  context,
  ssr,
  redirect,
}: {
  context: NextContext;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ssr?: (res: { context: NextContext; session: Session }) => any;
  redirect?: string;
}) => {
  const session = await getSession({ req: context.req });

  if (!session)
    return {
      redirect: {
        destination: redirect ? redirect : `/`,
        permanent: false,
      },
    };
  if (ssr) return ssr({ context, session });
  return {};
};
