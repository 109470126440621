import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

export const CustomIntercomStateContext = createContext<{ isOpen: boolean }>({
  isOpen: false,
});

export const useIsIntercomOpen = () => {
  const { isOpen } = useContext(CustomIntercomStateContext);

  return {
    isOpen,
  };
};

export const useHideIntercom = () => {
  const { update } = useIntercom();
  useEffect(() => {
    update({
      hideDefaultLauncher: true,
    });

    return () => {
      update({
        hideDefaultLauncher: false,
      });
    };
  }, [update]);
};

export const CustomIntercomStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const providerState = useMemo(() => ({ isOpen }), [isOpen]);

  const onHide = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onShow = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <IntercomProvider
      appId={'lysrkvrj'}
      apiBase="https://api-iam.eu.intercom.io"
      autoBoot
      initializeDelay={5000}
      onHide={onHide}
      onShow={onShow}
    >
      <CustomIntercomStateContext.Provider value={providerState}>
        {children}
      </CustomIntercomStateContext.Provider>
    </IntercomProvider>
  );
};
