import { newRelicNoticeError } from '../hooks/useNewRelic';

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigator: any;
  };
export const isLaunchedInPWA = () => {
  try {
    // Android
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
    // iOS
    if (window.navigator.standalone === true) {
      return true;
    }
    return false;
  } catch (e) {
    newRelicNoticeError(e, 'Error while checking if launched in PWA');
    return false;
  }
};
