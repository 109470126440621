export function isTouchDevice() {
  if (typeof window !== 'undefined') {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      navigator.msMaxTouchPoints > 0
    );
  }
  return false;
}

export const isDevelopment = () => {
  return process.env.NEXT_PUBLIC_APP_ENV === 'dev';
};

export const isProduction = () => {
  return process.env.NEXT_PUBLIC_APP_ENV === 'prod';
};

export const isPR = () => {
  return process.env.NEXT_PUBLIC_APP_ENV === 'pr';
};

export const isPrBranch = () => {
  return (
    process.env.NEXT_PUBLIC_APP_ENV === 'pr' &&
    typeof window !== 'undefined' &&
    window.location?.href?.includes('-pr.preview.curipod.org')
  );
};

export const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL as string;
export const getBaseCoreAPIUrl = () =>
  process.env.NEXT_PUBLIC_BASE_CORE_API_URL as string;

export const generateRedirectPayload = (existingState: string, from: string) =>
  `${existingState}|redirectStart${from}redirectEnd`;
