import { dehydrate, QueryClient } from '@tanstack/react-query';

import { DiscoverSearchQuery } from '../services/backendService/types';
import { notUndefined } from './general';

export const toTemplateSearchQueryCacheKey = (query: DiscoverSearchQuery) => {
  return JSON.stringify(
    [
      query.searchPhrase,
      query?.filters?.subjects,
      query?.filters?.hotFilter,
      query?.filters?.grades,
      query?.filters?.languages,
      query.cursor,
      query.limit,
    ].filter(notUndefined),
  );
};

export const dehydrateReactQueryPrefetchedInfiniteQuery = (queryClient: QueryClient) => {
  return JSON.parse(JSON.stringify(dehydrate(queryClient)));
};
