import { useRouter } from 'next/router';
import { createContext, ReactNode, useEffect, useRef } from 'react';
export const RouterHistoryContext = createContext<{ previousRoute?: string }>({
  previousRoute: undefined,
});
const RouterHistoryProvider = ({ children }: { children: ReactNode }) => {
  const { previousRoute } = useRouteUrlHistory();

  return (
    <RouterHistoryContext.Provider
      value={{
        previousRoute,
      }}
    >
      {children}
    </RouterHistoryContext.Provider>
  );
};
const EXCEPTIONS: string[] = []; // if we want to include exceptions to not override the context with

const useRouteUrlHistory = () => {
  const previousRouteRef = useRef<string>('');
  const router = useRouter();

  const handleBeforeHistoryChange = (url: string) => {
    const [nextUrl] = url?.split('?') || [];

    if (
      !(EXCEPTIONS.includes(nextUrl) || EXCEPTIONS.includes(router.asPath)) &&
      nextUrl !== router.asPath
    ) {
      previousRouteRef.current = router.pathname;
    }
  };
  // The current route is passed to the context before routing
  useEffect(() => {
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange);

    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { previousRoute: previousRouteRef.current };
};
export default RouterHistoryProvider;
