import { ById } from '../services/backendService/types';

/**
 * The purpose is to have one sorted array with semantic names of the order of zIndexes
 * The actual z-index values will be generated on boot.
 * You dont have to worry about the z-index values, only what its relative to.
 */
const ZIndexes = [
  // HIGHEST ZINDEX = closest to the front
  'GENERAL_OFFLINE_NOTIFICATION',
  'ANTD_SELECT_DROPDOWN',
  'ANTD_TOPBAR_DRAWER',
  'ANTD_DRAWER',
  'FULL_SIZE_MODAL',
  'BASE_MODAL_CROSS_ICON',
  'TEMPLATE_SETTINGS_MODAL',
  'SLATE_TOOLBAR',
  'DISCOVER_FILTERS_COUNT',
  'TOPBAR_DROPDOWN',
  'TOPBAR',
  'CREATOR_TOP_BAR',
  'SIDEBAR',
  'CREATOR_DRAWER',
  'DISCOVER_FILTERS',
  'MOBILE_TOP_NAV_LEFT_BAR',
  'LANDING_PAGE_HEADER',
  'DISCOVER_BROWSE_ARROW',
  'CURIMOTE_TEACHER_WINDOW_CONFIRM',
  'OLD_PRESENT_MODE_NAVIGATE_BUTTON',
  'CREATOR_SLIDE_EDITOR_TOOLBAR_OVERLAY',
  'CREATOR_SLIDE_EDITOR_TOOLBAR',
  'CREATOR_LOCKED_POSITION_OVERLAY',
  'CREATOR_MOVABLE_HANDLES',
  'CREATOR_MEDIA_DISABLED_OVERLAY',
  'TEMPLATE_PREVIEW_SIDE_PANEL',
  'CREATOR_BACKGROUND_DRAWING_SELECTOR_DONE_BUTTON',
  'CREATOR_MEDIA_SELECTOR_BUTTON',
  'VIDEO_URL_RENDERER',
  'CREATOR_TEMPLATE_SLIDE_OVERLAY',
  'CREATOR_MEDIA_SELECTOR_CROSS_ICON_WRAPPER',
  'ACTIVITY_OVERLAY_ITEM',
  'PRESENTER_TOP_BAR',
  'PRESENTER_ACTION_BUTTONS',
  'LIVEGAME_TIMER_WIDGET',
  'PRESENTER_ACTION_BUTTON_MUSIC_SLIDER',
  'PRESENTER_HIDE_RESPONSE',
  'PRESENTER_LOBBY',
  'PRESENTER_LOBBY_JOIN_CONTAINER',
  'PRESENTER_LOBBY_CONTAINER',
  'SHOW_WORDCLOUD_OVERLAY',
  'CREATOR_RIGHT_BAR',
  'SLIDE_RESIZE_HANDLE',
  'CURIPOD_LOGO',
  'LIVEGAME_COUNTDOWN_OVERLAY',
  'RESPONSECOUNTER_WIDGET_CONTAINER_CENTER',
  'CREATOR_SLIDE_VIEW',
  'MENTION_SLATE_LIST',
  'SLATE_OPTION_CONTAINER',
  'MAX_SIZE_MEDIA_CONTENT',
  'SLIDE_DRAG_HANDLE',
  'WYSIWYG_CONTENT_CONTAINER',
  'WYSIWYG_ADD_OPTION_BUTTON',
  'WYSIWYG_SLIDE_CONTAINER',
  'CREATOR_ROUND_SELECTOR_BETA_LABEL',
  'CREATOR_POLL_OPTIONS_CROSS_ICON',
  'CREATOR_RIGHT_BAR_DRAG_HANDLE',
  'LANDING_PAGE_VIDEO_PLAY_BUTTON_OVERLAY',
  'WYSIWYF_PIN_CODE',
  'CREATOR_LEFT_BAR',
  'CREATOR_LEFT_BAR_HIDE',
  'SLIDE_ELEMENT_TOOLBAR',
  'SMART_TEXT_AREA_BULLETS_EMOJI_SELECTOR',
  'DESIGNER_SLIDE_LIST',
  'DESIGNER_SLIDE_LIST_HIDE',
  'WORKSPACE_MAX_USERS_CIRCLE',
  'CREATORPAGE_CREATOR_CONTAINER',
  // LOWEST ZINDEX = closest to the back
] as const;

type ZIndexesType = typeof ZIndexes[number];

function generate() {
  const MIN = 1;
  const MAX = 3100;
  const diff = Math.ceil((MAX - MIN) / ZIndexes.length);
  return ZIndexes.reduce<ById<number>>(
    (acc, cur, i) => ({ ...acc, [cur]: MAX - i * diff }),
    {},
  );
}

const all = generate();

export const zIndexes = (type: ZIndexesType): number => {
  const value = all[type];
  return value || 1;
};
