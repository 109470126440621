import { debounce } from 'lodash';

import {
  GradeType,
  SchoolRoleType,
  SubjectType,
} from '../services/CreatorService/Creator.type';

export const runAsyncSequential = async <T, Z>(
  entities: Z[],
  task: (arg: Z) => Promise<T>,
) => {
  const starterPromise = Promise.resolve(null);
  const results: T[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await entities.reduce<any>(
    (p, entity) =>
      p.then(() =>
        task(entity).then(result => {
          results.push(result);
        }),
      ),
    starterPromise,
  );

  return results;
};

export const DEFAULT_REQUIRE_REAL_NAMES = true;

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export const SUPPORTED_LANGAUGES = [
  { value: 'en', displayName: 'English' },
  { value: 'nb-NO', displayName: 'Norsk' },
];

export const CURIPOD_SCHOOL_ROLE_OPTIONS: {
  value: SchoolRoleType;
  displayName: string;
}[] = [
  { value: 'Teacher', displayName: 'Teacher' },
  { value: 'Instructional/Tech Coach', displayName: 'Instructional/Tech Coach' },
  { value: 'Library Media Specialist', displayName: 'Library Media Specialist' },
  { value: 'School Administrator', displayName: 'School Administrator' },
  { value: 'District Administrator', displayName: 'District Administrator' },
  { value: 'Purchasing Manager', displayName: 'Purchasing Manager' },
  { value: 'Other', displayName: 'Other' },
];
export const CURIPOD_SUBJECT_OPTIONS: {
  value: SubjectType;
  displayName: string;
}[] = [
  { value: 'English Language Arts', displayName: 'English Language Arts' },
  { value: 'Math', displayName: 'Math' },
  { value: 'Science', displayName: 'Science' },
  { value: 'Social Studies', displayName: 'Social Studies' },
  { value: 'Other', displayName: 'Other' },
];
export const CURIPOD_GRADE_OPTIONS: {
  value: GradeType;
  displayName: string;
}[] = [
  { value: 'Kindergarten', displayName: 'Kindergarten' },
  { value: '1st', displayName: '1st' },
  { value: '2nd', displayName: '2nd' },
  { value: '3rd', displayName: '3rd' },
  { value: '4th', displayName: '4th' },
  { value: '5th', displayName: '5th' },
  { value: '6th', displayName: '6th' },
  { value: '7th', displayName: '7th' },
  { value: '8th', displayName: '8th' },
  { value: '9th', displayName: '9th' },
  { value: '10th', displayName: '10th' },
  { value: '11th', displayName: '11th' },
  { value: '12th', displayName: '12th' },
  { value: '13th', displayName: '13th' },
  { value: 'PD', displayName: 'PD' },
  { value: 'Higher Education', displayName: 'Higher Education' },
  { value: 'Other', displayName: 'Other' },
];

export const CURIPOD_FILTER_GRADE_OPTIONS: {
  value: string;
  displayName: string;
}[] = [
  {
    displayName: '12th',
    value: '12',
  },
  {
    displayName: '11th',
    value: '11',
  },
  {
    displayName: '10th',
    value: '10',
  },
  {
    displayName: '9th',
    value: '9',
  },
  {
    displayName: '8th',
    value: '8',
  },
  {
    displayName: '7th',
    value: '7',
  },
  {
    displayName: '6th',
    value: '6',
  },
  {
    displayName: '5th',
    value: '5',
  },
  {
    displayName: '4th',
    value: '4',
  },
  {
    displayName: '3rd',
    value: '3',
  },
  {
    displayName: '2nd',
    value: '2',
  },
  {
    displayName: '1st',
    value: '1',
  },
  {
    displayName: 'Kindergarten',
    value: '0',
  },
];

export const PRIVACY_SETTINGS = [
  { value: true, displayName: 'Public, lessons can be found in discover' },
  {
    value: false,
    displayName: 'Private, lessons will never be visible in discover',
  },
];

export const debounceEventIfNotTrusted = ({
  cb,
  isTrusted,
  debounceDuration = 500,
}: {
  cb: <T>() => T | void;
  isTrusted?: boolean;
  debounceDuration?: number;
}) => {
  // due to scripting, a browser event might not be trusted as it can originate from a script or other non trusted sources. This prevents the event from being fired multiple times
  if (isTrusted !== undefined && isTrusted) {
    cb();
  } else {
    debounce(cb, debounceDuration);
  }
};
